import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";


export default function Timestamp({ data }) {
    const { mdx: {
        body,
        frontmatter: {
            summary,
            title } }
    } = data

    return (
        <Layout fullMenu>
            <section id="wrapper">
                <header>
                    <div className="inner">
                        <h2>{title}</h2>
                        <p>{summary}</p>
                    </div>
                </header>

                <section id="one" className="wrapper spotlight style1">
                    <div className="inner">
                        <div className="content">
                            <MDXRenderer>{body}</MDXRenderer >
                        </div>
                    </div>
                </section>
            </section>

        </Layout >
    );
}

export const query = graphql`    {
                        mdx(frontmatter: {type: {eq: "timestamp"}}) {
                        body
    frontmatter {
                    type
                    date
                    summary
                    title
    }
  }
}
                    `;